@import '../../assets/global.scss';

$background-color_1: #fff;
$modal-width: 100%;
$modal-max-width: 500px;
$modal-max-height: 250px;
$modal-padding: 30px;
$modal-header-border-color: #dbdbdb;
$overlay-color: rgba(0, 0, 0, 0.5);

@mixin center-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.basic_confirm_modal_overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $overlay-color;
  z-index: 9998;
}

.basic_confirm_modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: $modal-width;
  max-width: $modal-max-width;
  max-height: $modal-max-height;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-name: appear;
  animation-duration: 300ms;

  .basic_confirm_modal_dialog {
    width: 100%;
    background: white;
    position: relative;
    margin: 0 $modal-padding;
    max-height: calc(100vh - 2 * $modal-padding);
    text-align: left;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow:
      0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
    animation-name: slide-in;
    animation-duration: 0.5s;
    border-radius: 8px;

    .basic_confirm_modal_header {
      @include center-flex;
      justify-content: flex-end;
      position: relative;
      padding: 5px;
      margin: 10px;

      .basic_confirm_modal_header {
        @extend .btn-close !optional;
        @extend .text-right !optional;
        @extend .position-absolute !optional;
        @extend .top-0 !optional;
        @extend .end-0 !optional;
        @extend .p-2 !optional;
        cursor: pointer;
        padding: 8px;
      }
    }

    .basic_confirm_modal_footer {
      @include center-flex;
      padding: $modal-padding;
      justify-content: flex-end;

      button {
        margin-left: 10px;
        font-size: 12px;
        padding: 8px 16px;
      }

      @media screen and (max-width: 767px) {
        justify-content: space-between;
        align-items: center;

        button {
          margin-left: 0;
          margin-top: 10px;
          width: 100%;
          max-width: 80px;
        }
      }
    }

    .basic_confirm_modal_title {
      overflow: auto;
      border-bottom: 1px solid $modal-header-border-color;
      padding: 5px;
      margin-left: 10px;
      margin-right: 10px;
    }

    .basic_confirm_modal_body {
      font-size: 12px;
      font-weight: 500;
      overflow-wrap: break-word;
      padding: 5px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.confirm_modal_title {
  overflow: auto;
  border-bottom: 1px solid $modal-header-border-color;
  padding: 5px;
}

.modal_content {
  padding: 1rem;
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(-150px);
  }

  to {
    transform: translateY(0);
  }
}
